
<template>
  <div>
    <b-overlay :show="assistant.isFetchingAssistant">
      <b-card class="mb-4">
        <AssistantForm />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  page: {
    title: 'Assistant detail',
  },
  components: {
    AssistantForm: () => import('./components/AssistantForm.vue'),
  },
  computed: {
    ...mapState(['assistant']),
    assistantId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPermissions', 'fetchAssistant']),
    async fetchData() {
      await this.fetchPermissions()
      if (this.assistantId) {
        await this.fetchAssistant(this.assistantId)
      }
    },
  },
}
</script>
